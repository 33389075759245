import React from "react";

function ErrorPage() {
  return (
    <div className="pageContentNoNav">
      <div className="container centeredPage">
        <h1>Ooops!</h1>
        <p>Something went wrong...</p>
      </div>
    </div>
  );
}

export default ErrorPage;
