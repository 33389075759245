import React from "react";
import AuthInput from "../components/AuthInput";
import Button from "../components/Button";
import { useAuthFunctions } from "../hooks/useAuthFunctions";

function ResetPasswordPage() {
  const { setNewPassword, password, setPassword, passwordConfirm, setPasswordConfirm, loading } = useAuthFunctions();

  return (
    <div className="pageContentNoNav">
      <div className="container centeredPage">
        <div className="authForm authFormHeader">
          <div className="centered">
            <img className="logo shaddow" src="./images/favicon.png" />
            <h2 className="authHeader">Reset Password</h2>
            <p className="lightText">It happens to the best of us!</p>
          </div>
        </div>

        <form onSubmit={setNewPassword}>
          <div className="authForm">
            <AuthInput className="col" label="Password" type="text" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete={"new-password"} display={true} required />
            <AuthInput className="col" label="Confirm Password" type="text" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} autoComplete="off" display={true} required />
          </div>

          <div className="authForm ">
            <Button style="signUpBtn" text="Submit" type="submit" loading={loading} />
            {loading && <p style={{ textAlign: "center" }}>This can take a couple minutes!</p>}
          </div>
        </form>
        <div className="authForm row centered signupAction"></div>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
