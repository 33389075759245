import React from "react";

function AuthInput({ className, label, labelAfter, type, placeholder, value, onChange, autoComplete, errors, errorIndex, display, required }) {
  if (display) {
    return (
      <div className={`formInputContainer ${className}`}>
        <label>{label}</label>
        <input type={type} placeholder={placeholder} value={value} onChange={onChange} autoComplete={autoComplete} className={errors && errors[errorIndex] ? "errorInput" : null} required={required} />
        <label>{labelAfter}</label>
        {/* <input type={type} placeholder={placeholder} value={value} onChange={onChange} autoComplete={autoComplete} className={"errorInput"} /> */}
      </div>
    );
  }
  return null;
}

export default AuthInput;
