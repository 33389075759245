import React from "react";
import Loader from "./Loader";

function Button({ type, text, iconLeft, iconRight, onClick, style, loading }) {
  if (type === "submit") {
    if (loading)
      return (
        <button className="mainButton">
          <Loader size={15} />
        </button>
      );
    return <input className={style ? style : "mainButton"} type="submit" value={text} />;
  }
  return (
    <button className={style ? style : "mainButton"} onClick={onClick}>
      {loading && <Loader size={15} />}

      {!loading && iconLeft && <img className="iconLeft" src={iconLeft} />}
      {!loading && text}
      {!loading && iconRight && <img className="iconRight" src={iconRight} />}
    </button>
  );
}

export default Button;
