import React from "react";
import { Link } from "react-router-dom";

const data = [
  {
    title: "Courses & Challenges",
    description: "Listen to successful coaches who use these tools in their daily lives to get the most out of their day!",
    image: "./assets/course.svg",
  },
  {
    title: "Get Aligned",
    description: "Start your day the right way! Answer some intention setting questions designed to align you for success.",
    image: "./assets/Aligned.svg",
  },
  {
    title: "Morning Routine",
    description: "Build a morning routine optimized for you, and ensure that you actually stick with it!",
    image: "./assets/routine.svg",
  },
  {
    title: "Daily Checklist",
    description: "Organize that never ending list of todos in a way that allows you to boost your productivity.",
    image: "./assets/checklist.svg",
  },
  {
    title: "Evening Reflection",
    description: "Finish your day by looking back on all that you've accomplished. Sleep well knowing that you're living aligned!",
    image: "./assets/reflect.svg",
  },
  {
    title: "Interactive Calendar",
    description: "Reflect on your daily events to find out what went well and what could have gone better. Sync your calendar across all your devices.",
    image: "./assets/calendar.svg",
  },
];

const HomePage = () => {
  return (
    <>
      <header className="header" id="header">
        <nav className="nav container">
          <a href="#" className="nav__logo">
            <img src="assets/logo_bw.svg" alt="logo" width="100px" />
          </a>
          <a href="https://api.livealigned.app/api/referral?source=web" className="button button__header">
            Download Now!
          </a>
        </nav>
      </header>
      <main className="main">
        {/* home */}
        <section className="home section" id="home">
          <div className="home__container container grid">
            <div className="home__data">
              <h1 className="home__title">
                Enrich your life
                <br />
                with Live aligned
              </h1>
              <p className="home__description">
                Our mission is to assist people in starting their day{" "}
                <i>
                  <b>right</b>
                </i>{" "}
                and changing their{" "}
                <i>
                  <b>lives</b>
                </i>{" "}
                through simple, responsive and intentional technology routines.
              </p>
              <a href="https://api.livealigned.app/api/referral?source=web" className="button">
                Get Started
              </a>
            </div>

            <img src="./assets/live aligned main.svg" className="home__img" alt="live_aligned_feature_image"></img>
          </div>
        </section>

        <section className="home_video container">
          <iframe
            className="home_img"
            viewBox="0 0 312 236"
            width="100%"
            height="500"
            src="https://www.youtube.com/embed/qms9Bo5roMk?si=FvVM5yRT3CUSl_R6"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </section>

        {/* feature */}
        <section className="feature section" id="feature">
          <div className="feature__section">
            {data.map((el, i) => {
              return (
                <div key={i} className="feature__flex">
                  <img src={el.image} className="feature__img"></img>
                  <div className="feature__data">
                    <h3 className="section__title-center">{el.title}</h3>
                    <p className="feature__description">{el.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
        {/* footer */}
        <section className="footer_links" id="footer_links">
          <div className="legals">
            <p>
              <Link to="/privacy-policy" className="legal_link">
                Privacy Policy
              </Link>
            </p>
            <p>
              <Link to="/terms-of-service" className="legal_link">
                Terms of Service
              </Link>
            </p>
          </div>
        </section>
      </main>
    </>
  );

  // return (
  //   <>
  //     <div className="section-8 wf-section">
  //       <div className="container-3 w-container">
  //         <div className="div-block-18">
  //           <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
  //             <img src="images/logo.svg" loading="lazy" alt="" className="image-4" />
  //             <a href="https://api.livealigned.app/api/referral?source=web" className="buttonApp">
  //               <img src="images/app_store.png" loading="lazy" alt="" className="image-4" />
  //             </a>
  //           </div>
  //           <div className="imgBox">
  //             <img src="images/landing_phone_1.png" loading="lazy" alt="" className="image-7" />
  //             <img src="images/landing_phone_2.png" loading="lazy" alt="" className="image-7 behind left" />
  //             <img src="images/landing_phone_3.png" loading="lazy" alt="" className="image-7 behind right" />
  //           </div>
  //         </div>

  //         <div className="div-block-20">
  //           <p className="paragraph-2">
  //             Our mission is to assist people in <em>starting their day </em>
  //             <strong>
  //               <em>right</em>
  //             </strong>{" "}
  //             and <em>changing their </em>
  //             <strong>
  //               <em>lives </em>
  //             </strong>
  //             through simple, responsive and intentional technology routines.
  //           </p>
  //         </div>
  //         <div className="div-block-17">
  //           <a href="#info" className="back-btn w-inline-block">
  //             <div className="link-block">
  //               <div className="div-block-16 left" />
  //               <div className="div-block-16 right" />
  //             </div>
  //             <div className="link-block">
  //               <div className="div-block-16 left" />
  //               <div className="div-block-16 right" />
  //             </div>
  //           </a>
  //         </div>
  //       </div>
  //     </div>
  //     <div id="info" className="section-6 wf-section">
  //       <div className="container-2 w-container">
  //         <div className="w-layout-grid grid-2">

  //         </div>
  //       </div>
  //     </div>
  //     <div className="footer">
  //
  //     </div>
  //   </>
  // );
};

export default HomePage;
