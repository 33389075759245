import React from "react";

function Loader({ size }) {
  const width = size ? size + "px" : "120px";
  const height = size ? size + "px" : "120px";
  const border = size ? size / 10 + "px" : "16px";

  return <div className="loader" style={{ width: width, height: height, borderWidth: border }}></div>;
}

export default Loader;
