// import "./css/App.css";
// import "./css/Auth.css";
// import "./css/Button.css";
// import "./css/Input.css";
// import "./css/Variables.css";

import Navigation from "./navigation/Navigation";

function App() {
  return <Navigation />;
}

export default App;
