// DOCS:
// https://reactrouter.com/docs/en/v6

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorPage from "../pages/ErrorPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import HomePage from "../pages/HomePage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import PrivacyPolicyPageGoogle from "../pages/PrivacyPolicyPageGoogle";
import TermsPage from "../pages/TermsPage";

function Navigation(props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/terms-of-service" element={<TermsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPageGoogle />} />
        <Route path="/privacy-policy/other" element={<PrivacyPolicyPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Navigation;
