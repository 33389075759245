import axios from "axios";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useAuthFunctions = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);

  const setNewPassword = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);

    if (!password || !passwordConfirm || password !== passwordConfirm || !code) {
      alert("Error!");
      setLoading(false);
      return;
    }
    // make request

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/auth/reset-password`,
      headers: {
        "Content-Type": "application/json",
      },

      data: JSON.stringify({
        code: code,
        password: password,
        passwordConfirmation: passwordConfirm,
      }),
    };

    try {
      // handle request
      const response = await axios(config);

      if (response.data) {
        setLoading(false);
        alert("Success! Please go log into the app.");
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong...");
    }
  };

  return {
    setNewPassword,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    loading,
  };
};
